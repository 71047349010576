import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Auth from "../views/Auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/prijava",
    name: "Prijava",
    component: Auth,
  },
  {
    path: "/rsvp",
    name: "RSVP",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RSVP.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  //console.log("to: " + JSON.stringify(to) + "; from: " + JSON.stringify(from) + "; next: " + next);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const loggedIn = localStorage.getItem("srdjanianapin") != null;
    if (!loggedIn) {
      return next("/prijava");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
