<template>
  <div class="page-wrapper">
    <!-- start preloader -->
    <!--    <div class="preloader">-->
    <!--      <div class="middle">-->
    <!--        <i class="fi flaticon-favorite-heart-button"></i>-->
    <!--        <i class="fi flaticon-favorite-heart-button"></i>-->
    <!--        <i class="fi flaticon-favorite-heart-button"></i>-->
    <!--        <i class="fi flaticon-favorite-heart-button"></i>-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- end preloader -->

    <!-- Start header -->
    <header id="header" class="site-header header-style-2">
      <nav class="navigation navbar navbar-default">
        <div class="container">
          <div class="navbar-header">
            <button type="button" class="open-btn">
              <router-link to="/rsvp">RSVP</router-link>
            </button>
            <router-link to="/" class="navbar-brand" id="header-nav-names"
              >{{ wedding.brideFirstName }} <i class="fi flaticon-spring"></i>
              {{ wedding.groomFirstName }}</router-link
            >
          </div>
          <div
            id="navbar"
            class="navbar-collapse collapse navbar-right navigation-holder"
          >
            <button class="close-navbar"><i class="ti-close"></i></button>
            <ul class="nav navbar-nav">
              <li><router-link to="/rsvp">RSVP</router-link></li>
            </ul>
          </div>
          <!-- end of nav-collapse -->
        </div>
        <!-- end of container -->
      </nav>
    </header>
    <!-- end of header -->

    <!-- start of hero -->
    <section class="hero-slider hero-static">
      <div class="wedding-announcement">
        <div class="couple-text">
          <transition name="bounce" appear>
            <h3 id="curve-text" v-if="show">Venčavamo se!</h3>
          </transition>
          <transition name="slide-fade" appear>
            <h2 class="couple-name" v-if="show">
              {{ wedding.brideFirstName }} &amp; {{ wedding.groomFirstName }}
            </h2>
          </transition>
        </div>
      </div>

      <div
        class="slide-inner slide-bg-image"
        data-background="assets/images/slider/slide-1.jpg"
      ></div>
      <!-- end slide-inner -->
    </section>
    <!-- end of hero slider -->

    <!-- end of hero slider -->

    <!-- start wedding-date -->
    <section class="wedding-date section-padding">
      <div class="container">
        <div class="row">
          <div class="col col-xs-12">
            <div class="section-title">
              <h2>Datum venčanja</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-xs-12">
            <h3 id="short-wedding-date">{{ getWeddingDateShort() }}</h3>
            <div class="clock-grids">
              <div id="clock">
                <div class="box">
                  <div>
                    <div class="time">{{ days | two_digits }}</div>
                    <span>Dana</span>
                  </div>
                </div>
                <div class="box">
                  <div>
                    <div class="time">{{ hours | two_digits }}</div>
                    <span>Sati</span>
                  </div>
                </div>
                <div class="box">
                  <div>
                    <div class="time">{{ minutes | two_digits }}</div>
                    <span>Minuta</span>
                  </div>
                </div>
                <div class="box">
                  <div>
                    <div class="time">{{ seconds | two_digits }}</div>
                    <span>Sekundi</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end container -->
    </section>
    <!-- end wedding-date -->


    <!-- start couple-section -->
    <section class="couple-section section-padding" id="couple">
      <div class="container">
        <div class="row">
          <div class="col col-xs-12">
            <div class="couple-area clearfix">
              <div class="text-grid bride">
                <h3 id="middle-bride-name">{{ wedding.brideName }}</h3>

              </div>
              <div class="middle-couple-pic">
                <img src="@/assets/images/couple.jpg" alt />
              </div>
              <div class="text-grid groom">
                <h3 id="middle-groom-name">{{ wedding.groomName }}</h3>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end container -->
    </section>
    <!-- end couple-section -->

    <!-- start love-sotry-section -->
    <section class="love-sotry-section section-padding" id="story">
      <div class="container">
        <div class="row">
          <div class="col col-xs-12">
            <div class="section-title">
              <h2>O venčanju</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-xs-12">
            <div class="story-grids">
              <div class="grid">
                <h3>Žurka za odrasle</h3>
                <p>Zbog pirode prostora i muzike odlučili smo da naša svadba bude slavlje za odrasle. Zamolili bismo vas da svoje mališane ostavite bakama i dekama na čuvanje, a vi dođite da se dobro provedemo. Za njih, spremili smo ono što najviše vole - parče torte za poneti. :)</p>
              </div>
              <div class="grid">
                <h3>Dress code</h3>
                <p>Obucite ono u čemu se najprijatnije i najlepše osećate</p>
              </div>
              <div class="grid">
                <h3>Fotografije</h3>
                <p>Sve fotografije biće dostupne nakon slavlja na sajtu. Budite slobodni da fotografije i snimke koje ste sami napravili podelite sa nama na sajtu i upotpunite naše uspomene</p>
              </div>
              <div class="grid">
                <h3>Pokloni za goste</h3>
                <p> Za sve goste spremili smo poklon iznenađenja</p>
              </div>
              <img src="../assets/images/ring.png" alt>
            </div>
          </div>
        </div>
      </div> <!-- end container -->
    </section>
    <!-- end love-sotry-section -->
    <section >

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2809.761724989161!2d19.83927821576112!3d45.23238745690193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b1003166d72d7%3A0xaa4d87a9da9830c3!2sSplav%20Aqua%20Royal!5e0!3m2!1sen!2srs!4v1629323963579!5m2!1sen!2srs" width="100%" height="600" style="border:0;" allowfullscreen="true" loading="lazy"></iframe>


    </section>
    <!-- start wedding-location-section -->
    <section class="wedding-location-section section-padding" id="events">
      <div class="container">
        <div class="row">
          <div class="col col-xs-12">
            <div class="section-title">
              <h2>Venčanje</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-xs-12">
            <div class="location-grids clearfix">
              <div class="grid">
                <div class="img-holder">
                  <img src="../assets/images/reception-pic.jpg" alt />
                </div>
                <h3>{{ wedding.mainVenueName }}</h3>
                <ul>
                  <li>
                    <i class="ti-location-pin"></i> {{ wedding.mainVenueAddress }}
                  </li>
                  <li><i class="ti-time"></i> {{ getWeddingDateShort() }}</li>
                </ul>
                <p>
                  Splav na Dunavu sa pogledom na Frušku Goru
                </p>
                <button
                  class="see-location-btn" @click="showLocationModal = true"
                  >
                  Otvori mapu<i class="fa fa-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end wedding-location-section -->

    <!-- start footer-section -->
    <footer class="footer-section">
      <div class="container">
        <div class="row">
          <div class="col col-xs-12">
            <div class="footer-area">
              <div class="couple-name">
                <h2 id="footer-couple-names">
                  {{ wedding.brideFirstName }} <br />& <br />{{
                    wedding.groomFirstName
                  }}
                </h2>
              </div>
              <h3>Hvala vam što ste ulepšali naš dan!</h3>
            </div>
          </div>
        </div>
      </div>


      <!-- end container -->
    </footer>
    <!-- end footer-section -->
    <vue-modaltor :visible="showLocationModal" @hideModal="showLocationModal = false" :resize-width='{1200:"60%",992:"80%",768:"90%"}'>
      <template #body>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2809.761724989161!2d19.83927821576112!3d45.23238745690193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b1003166d72d7%3A0xaa4d87a9da9830c3!2sSplav%20Aqua%20Royal!5e0!3m2!1sen!2srs!4v1629323963579!5m2!1sen!2srs" width="100%" height="600" style="border:0;" allowfullscreen="true" loading="lazy"></iframe>

      </template>
    </vue-modaltor>
  </div>
</template>

<script>
import api from "../helpers/axios";
import moment from "moment-timezone";


export default {
  name: "Home",

  components: {},

  mounted() {
    moment.locale("sr");
    this.initWedding();
    window.setInterval(() => {
      this.now = Math.trunc((new Date()).getTime() / 1000);
    },1000);
  },

  computed: {
    dateInMilliseconds() {
      return Math.trunc(Date.parse(this.date) / 1000)
    },
    seconds() {
      return (this.dateInMilliseconds - this.now) % 60;
    },
    minutes() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
    },
    hours() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
    },
    days() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
    }
  },

  data() {
    return {
      wedding: {},
      showLocationModal: false,
      show: false,
      date: new Date(),
      now: Math.trunc((new Date()).getTime() / 1000)
    };
  },

  methods: {
    initWedding() {
      api.get("/").then((response) => {
        this.wedding = response.data;
        this.show = true;
        this.date = new Date(this.wedding.weddingStart * 1000);
      });
    },

    getWeddingDateShort() {
      return moment
        .unix(this.wedding.weddingStart)
//        .tz(this.wedding.weddingStartTimezone)
        .format("D. MMMM YYYY HH:mm");
    },
  },
};
</script>
<style>
@import "../assets/css/themify-icons.css";
@import "../assets/css/flaticon.css";
@import "../assets/css/bootstrap.min.css";
@import "../assets/css/animate.css";
@import "../assets/css/slick.css";
@import "../assets/css/slick-theme.css";
@import "../assets/css/swiper.min.css";
@import "../assets/css/jquery.fancybox.css";
@import "../assets/css/magnific-popup.css";
@import "../assets/css/style.css";

input[type="checkbox"] {
  display: none;
}
label {
  cursor: pointer;
}

input[type="checkbox"] + label:before {
  border: 1px solid #000;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  width: 16px;
  margin: 0 0.5em 0 0;
  padding: 0;
  vertical-align: top;
}
input[type="checkbox"]:checked + label:before {
  background: #fff;
  color: #333;
  content: "\2713";
  text-align: center;
}

.slide-bg-image {
  background-image: url("../assets/images/slider/slide-3.jpg");
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(0px);
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
