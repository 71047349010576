import axios from "axios";
import getPin from "./utils";

let api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  proxy: false,
});

api.interceptors.request.use(function (config) {
  config.headers.PIN = getPin();

  return config;
});

export default api;