import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueModalTor from "vue-modaltor/dist/vue-modaltor.common";
import "vue-modaltor/dist/vue-modaltor.css";

Vue.config.productionTip = false;
Vue.filter("two_digits", (value) => {
  if (value < 0) {
    return "00";
  }
  if (value.toString().length <= 1) {
    return `0${value}`;
  }
  return value;
});

Vue.use(VueModalTor,  // add custome options
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
