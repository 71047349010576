<template>
  <!-- start rsvp-section -->
  <div class="page-wrapper">
    <section class="rsvp-section section-padding" id="rsvp">
      <div class="container">
        <div class="row">
          <div class="col col-xs-12">
            <div class="rspv-form">
              <h3>Pristup sajtu</h3>
              <p>Molimo vas unesite PIN kod sa pozivnice</p>

              <form id="rsvp-form" class="clearfix form validate-rsvp-form row">
                <input v-model="pin" placeholder="PIN" class="pin-code-input" />

                <div>
                  <div id="loader">
                    <i class="ti-reload"></i>
                  </div>
                </div>
                <div class="submit-btn">
                  <button type="submit" class="theme-btn" @click.prevent="authWithPin()">
                    Prijavi se
                  </button>
                </div>
                <div class="success-error-message">
                  <div id="success" v-if="this.success">Dobrodošli!</div>
                  <div id="error" v-if="this.failure">
                    PIN nije ispravan, molimo Vas pokušajte ponovo
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end container -->
    </section>
  </div>
  <!-- end rsvp-section -->
</template>

<script>
import api from "../helpers/axios";

export default {
  name: "Auth",

  data() {
    return {
      pin: "",
      success: false,
      failure: false,
      pinUrl: "/pin"
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    init() {
      api
        .post(this.pinUrl, { pin: localStorage.getItem("srdjanianapin") })
        .then(() => {
          this.$router.push("/");
        })
    },

    authWithPin() {
      api
        .post(this.pinUrl, { pin: this.pin })
        .then(async () => {
          localStorage.setItem("srdjanianapin", this.pin);
          this.success = true;
          this.failure = false;
          this.sleep(2000).then(() => {
            this.$router.push("/rsvp");
          });
        })
        .catch((error) => {
          console.log(error);
          this.success = false;
          this.failure = true;
        });
    }
  },
};
</script>

<style>
@import "../assets/css/themify-icons.css";
@import "../assets/css/flaticon.css";
@import "../assets/css/bootstrap.min.css";
@import "../assets/css/animate.css";
@import "../assets/css/slick.css";
@import "../assets/css/slick-theme.css";
@import "../assets/css/swiper.min.css";
@import "../assets/css/jquery.fancybox.css";
@import "../assets/css/magnific-popup.css";
@import "../assets/css/style.css";

.pin-code-input {
  text-align: center;
}
</style>
